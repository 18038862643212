import React, {useEffect} from 'react'

function Devices(){
    useEffect(()=>{
        document.title = "Tether.io"
    });
    
    return(
        <div className="text-center">
            <label>Devices Page</label>
        </div>
    )
}
export default Devices