import React, {useEffect, useState} from 'react';
import './App.css';
import {useHistory} from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import { Button } from 'react-bootstrap';

function App() {

	let history = useHistory()
	const [username, setUsername] = useState("")
	const [password, setPassword] = useState("")
	
	const inputValidation = () => {
		if(username === 'admin' && password === 'password'){
			history.push("/tabs")
		}
	}

	useEffect(()=>{
        document.title = "Tether.io"
    })
	

  	return (
		<div className="App">
			<Form onSubmit={inputValidation}>

				<Form.Group controlId="username.control">
					<Form.Label>Username:</Form.Label>
					<Form.Control type='text' name="username"  onChange={(e) => setUsername(e.currentTarget.value)}/>
				</Form.Group>

				<Form.Group controlId="password.control">
					<Form.Label>Password:</Form.Label>
					<Form.Control type='password' name="password"  onChange={(e) => setPassword(e.currentTarget.value)}/>
				</Form.Group>
				
				<Button variant="primary" type="submit">Login</Button>
				
			</Form>
		</div>
  	);
}

export default App;
