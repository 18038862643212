import React, {useEffect, useState} from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import '../css/Organisations.css'

function Organisations(){

    useEffect(()=>{
        document.title = "Tether.io"
    });

    const [id, setId] = useState("")
    const [name, setName] = useState("")

    return (
        <div>

            <h1>Search Organisations</h1>

            <Form className="text-center">

                <Form.Group controlId="id.control">
                    <Form.Label>ID</Form.Label>
                    <Form.Control type='text' name="id" value={id} onChange={(e) => setId(e.currentTarget.value)}/>
                </Form.Group>

                <Form.Group controlId="name.control">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type='text' name="name" value={name} onChange={(e) => setName(e.currentTarget.value)}/>
                </Form.Group>

                <Button variant="primary" type="submit">Search</Button>
                
                <Button variant="primary" type="submit">Log</Button>

            </Form>
        </div>
    )
}
export default Organisations