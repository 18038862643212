import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router } from "react-router-dom";
import './index.css';
import App from './App';
import TabMenu from './routes/Tabs'

const routing = (
  <Router>
    <div>
      <Route exact path ='/' component = {App} />
      <Route path ='/tabs' component = {TabMenu} />
    </div>
  </Router>
)
ReactDOM.render(routing, document.getElementById('root'))