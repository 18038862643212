import React, {useState} from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import "bootstrap/dist/css/bootstrap.min.css";
import Home from './Organisations'
import Customers from './Customers'
import Devices from './Devices'

function TabMenu(){

    const [key, setKey] = useState<String | null>("organisations")
    
    return(
        <div className="text-center">

            <Tabs id="tabs"
                activeKey={key}
                onSelect={(k)=>setKey(k)}
                >
               
                <Tab eventKey="organisations" title="Organisations">
                    <Home />
                </Tab>

                <Tab eventKey="customers" title="Customers">
                    <Customers />
                </Tab>
                
                <Tab eventKey="devices" title="Devices">
                    <Devices />
                </Tab>

            </Tabs>
        </div>
    )
}
export default TabMenu