import React, {useEffect} from 'react'

function Customers(){
    
    useEffect(()=>{
        document.title = "Tether.io"
    });
    
    return(
        <div className="text-center">
            <label>Customers Page</label>
        </div>
    )
    
}
export default Customers